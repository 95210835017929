.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.videoPlayer {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.videoArea {  
  margin: 50px auto;
  width: 70%px;
  height: 530px;
  border: 2px solid gray;
  background-color: #000;
  margin-top: 0px;
}

.listArea {
  padding-top: 10px;
  max-height: 600px;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  background-color: rgba(0,0,0,0.7);
}

.uploadArea {
  width: 600px;
  margin: 10px auto;
}

/* .listView {
  height: 250px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: gray;
  position: relative;
  overflow: hidden;
} */

.listView {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid gray;
}

.listView > button {  
  width: 25px;
  height: 25px;
  border-radius: 2px;
  border: none;
  background-color: red;
  color: #fff;
  text-align: center;
  margin-right: 5px;
}

.listView > h4 {
  padding: 10px;
  color: #eee;
  font-size: 16px;  
}

.listView > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #eee
}

.caption {
  border: none;
  padding: 10px;
  width: 90%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.uploadbtn {
  border: none;
  color: #fff;
  padding: 10px;
  width: 120px;
  background-color: orange;
  border-radius: 10px;
  margin-top: 10px
}

.progress {
  width: 90%;
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  background-color: orange;
}

.footer {
  height: 200px;
  background-color: #000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
